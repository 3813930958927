export const onBoardingWelcomeTypologieText = {
  injured: ['WelcomeTypologieTextInjured', 'WelcomeTypologieTextInjured2'],
  performer: ['WelcomeTypologieTextPerfomer', 'WelcomeTypologieTextPerfomer2'],
  lifestyle: [
    'WelcomeTypologieTextLifestyle',
    'WelcomeTypologieTextLifestyle2',
  ],
};

export const onboardingAnalysisPresentation = {
  man: {first: 'AnalysisPresentation', second: 'AnalysisPresentationSecondary'},
  woman: {
    first: 'AnalysisPresentationWoman',
    second: 'AnalysisPresentationSecondary',
  },
};

export const analysisPresentationScore = {
  performer: 'AnalysisPresentationScorePerformer',
  injured: 'AnalysisPresentationScoreInjured',
  lifestyle: 'AnalysisPresentationScoreLifestyle',
};

export const segments = [
  {
    score_text: 'score_good',
    item: 'RightAnkle',
    text: 'Feet',
    explanation: 'FeetExpla',
    desc: {
      fr: "Pose médio-pied (C'est a dire la partie la plus large de votre pied). Avoir une telle attaque au sol est idéale car elle limite les tensions musculaires et permets ainsi un bon équilibre corporel avec l'intension d'aller vers l'avant. ",
      en: 'Midfoot pose (i.e. the widest part of your foot). Having such an attack on the ground is ideal because it limits muscular tension and allows a good body balance with the intention of going forward.',
      zh: '中脚掌姿势（即脚掌最宽的部分）。这种击球方式非常理想，因为它可以限制肌肉紧张，让身体在向前移动的同时保持适当的平衡。',
      de: 'Midfoot-Haltung (d.h. der breiteste Teil Ihres Fußes). Eine solche Bodenberührung ist ideal, weil sie muskuläre Spannung begrenzt und eine gute Körperbalance mit der Absicht, nach vorne zu gehen, ermöglicht.',
    },
  },
  {
    score_text: 'score_okay',
    scoreNumber: 0.58,
    item: 'RightEar',
    text: 'Head',
    explanation: 'HeadExpla',
    desc: {
      angle: -8,
      fr: "Votre regard est un peu porté vers le sol. Vous devriez être entre 1° et -9° . Elevez votre regard et pensez à le porter loin à l'horizon: 20/30m devant vous",
      en: 'Your eyes are slightly down to the ground. You should be between 2° and -8°. Raise your sight and think of carrying it far to the horizon: 20/30m in front of you',
      zh: '您正俯视地面。你的视线应在 1° 和 -9° 之间。抬起视线，记住要向前看：20/30 米。',
      de: 'Ihre Augen sind leicht zum Boden geneigt. Sie sollten zwischen 2° und -8° sein. Heben Sie Ihren Blick und denken Sie daran, ihn weit bis zum Horizont zu tragen: 20/30m vor Ihnen.',
    },
  },
  {
    score_text: 'score_okay',
    scoreNumber: 0.58,
    item: 'trunk',
    text: 'Trunk',
    explanation: 'TrunkExpla',
    desc: {
      angle: 4,
      fr: 'Vous êtes penché en avant mais pas de manière optimale. Vous devriez être entre 5° et 11°.',
      en: 'In running, the intention should always be to move forward. Having an upper body posture that is too upright does not fully contribute to this. You should be between 4° and 10°. This can cause muscle tension. So remember to relax your shoulders and tilt your chest slightly forward.',
      zh: '您的身体前倾，但不是最佳状态。您应该在 5° 到 11° 之间。',
      de: 'Beim Laufen sollte die Absicht immer sein, nach vorne zu bewegen. Eine zu aufrechte Oberkörperhaltung trägt nicht vollständig dazu bei. Sie sollten zwischen 4° und 10° sein. Dies kann Muskelspannungen verursachen. Denken Sie also daran, Ihre Schultern zu entspannen und Ihre Brust leicht nach vorne zu neigen.',
    },
  },
  {
    score_text: 'score_okay',
    scoreNumber: 0.41,
    item: 'arm',
    text: 'Arm',
    explanation: 'ArmExpla',
    desc: {
      angle: 68,
      fr: "L'angle au coude est trop grand. Vous devriez être entre 75° et 85°. Une bonne position + coordination des bras et des jambes sont essentielles. Ils permettent de réduire la consommation d'énergie ainsi que les tensions musculaires. ",
      en: 'The angle at the elbow is too large. You should be between 75° and 85°. A good position + coordination of arms and legs are essential. They help to reduce energy consumption and muscle tension. It is thus necessary to work the position of the arms with an angle with the elbows close to 90° (right angle).',
      zh: '肘部角度过大。应在 75° 至 85° 之间。良好的姿势和手脚协调至关重要。它们有助于减少能量消耗和肌肉紧张。',
      de: 'Der Winkel am Ellenbogen ist zu groß. Sie sollten zwischen 75° und 85° sein. Eine gute Position + Koordination von Armen und Beinen sind essenziell. Sie helfen, den Energieverbrauch und die Muskelspannung zu reduzieren. Es ist daher notwendig, die Position der Arme mit einem Winkel nahe 90° (rechter Winkel) an den Ellenbogen zu arbeiten.',
    },
  },
  {
    score_text: 'score_good',
    scoreNumber: 0.92,
    item: 'forwardCycle',
    text: 'FrontKnee',
    explanation: 'FrontKneeExpla',
    desc: {
      angle: 146,
      fr: "Quelque soit votre pose du pied au sol, un tel angle au niveau du genou montre que vous avez l'intension d'aller vite vers l'avant et de 'griffer le sol'. Gardez cette intention de rester haut sur vos appuis. ",
      en: "Whatever your foot placement on the ground, such an angle at the knee shows that you intend to move forward quickly and 'claw the ground'. Keep that intention to stay high on your feet.",
      zh: '无论你的脚在地面上的位置如何，膝盖的这个角度都表明你打算快速向前移动并 "抓地"。保持你的意图，保持脚的高度。',
      de: '“Dein Kniewinkel ist optimal und zeigt deine Absicht schnell voran zu schreiten.',
    },
  },
  {
    score_text: 'score_bad',
    scoreNumber: 0.3,
    item: 'backCycle',
    text: 'BackKnee',
    explanation: 'BackKneeExpla',
    desc: {
      angle: 118,
      fr: 'La jambe arrière à un cycle trop bas ou trop rasant par rapport au sol. Vous aurez tendance a faire des petits pas. Vous devriez avoir un angle au genou de 110° et moins. Gardez pour intention de rester haut sur vos appuis. ',
      en: 'The back leg cycles too closely to the ground. You will tend to take small steps. You should have a knee angle of 101° and less. Keep your intention to stay high on your feet.',
      zh: '后腿循环过低或离地面过低。你会倾向于迈小步。膝关节角度应为 110°或更小。目标是保持高抬腿。',
      de: 'Dein aktives Bein ist zu niedrig bei seiner Rückkehr und führt dazu kleinere Schritte zu machen.',
    },
  },
];

export const tutoVideoIntro = {
  athlete: 'AthleteIntroTutoVideo',
  coach: 'CoachIntroTutoVideo',
  professional: 'CoachIntroTutoVideo',
};

export const accountCreationHeadline = {
  athlete: 'AccountCreationHeadline',
  coach: 'AccountCreationHeadlineCoach',
  professional: 'AccountCreationHeadlineCoach',
};
