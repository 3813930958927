import {remoteConfig} from 'utils/firebaseInit';
import {fetchAndActivate, getValue} from 'firebase/remote-config';
import getUserLocale from 'get-user-locale';
import {useEffect, useState} from 'react';
import {store} from 'store';
import {COACH} from 'constant/constants';

const userLanguage = store.getState().user?.settings?.selectedLanguage;
const userType = store.getState().user?.userType;

export let USER_LOCALE = userLanguage
  ? userLanguage
  : getUserLocale() === 'fr-FR'
  ? 'fr'
  : getUserLocale() === 'de-DE'
  ? 'de'
  : getUserLocale() === 'zh-CN'
  ? 'zh'
  : 'en';

store.subscribe(() => {
  const userLanguage = store.getState().user?.settings?.selectedLanguage;
  USER_LOCALE = userLanguage
    ? userLanguage
    : getUserLocale() === 'fr-FR'
    ? 'fr'
    : getUserLocale() === 'de-DE'
    ? 'de'
    : getUserLocale() === 'zh-CN'
    ? 'zh'
    : 'en';
});

export const GLOBAL_LOCALE = getUserLocale();

export function useRemoteConfigInit() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      fetchAndActivate(remoteConfig).then(fetchedRemotely => {
        if (fetchedRemotely) {
          console.log('Configs were retrieved from the backend and activated.');
        } else {
          console.log(
            'No configs were fetched from the backend, and the local configs were already activated',
          );
        }
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  return loading;
}

export function translate(key) {
  try {
    const res = getValue(remoteConfig, USER_LOCALE);
    if (res.asString()) {
      const result = JSON.parse(res.asString());
      const keyWithProfile = `${key}.${userType}`;
      if (result[keyWithProfile]) {
        return result[keyWithProfile];
      }
      const keyWithCoach = `${key}.${COACH}`;
      if (result[keyWithCoach]) {
        return result[keyWithCoach];
      }
      if (result[key]) {
        return result[key];
      }
      return '';
    }
  } catch (err) {
    console.log(err);
  }
}

export function onBoardingTranslate(key) {
  try {
    const res = getValue(remoteConfig, 'onboarding_' + USER_LOCALE);
    if (res.asString()) {
      const result = JSON.parse(res.asString());
      let str = result[key];
      if (!str) {
        return '';
      }
      return str;
    }
  } catch (err) {
    console.log(err);
  }
}
