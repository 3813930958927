export const NAVIGATION_CONFIG_ATHLETE = [
  {
    title: 'Analysis',
  },
  {
    title: 'Style',
  },
  {
    title: 'Metrics',
  },
];

export const NAVIGATION_CONFIG = [
  {
    title: 'Analysis',
  },
  {
    title: 'Metrics',
  },
  {
    title: 'Graph',
  },
  {
    title: 'Style',
  },
];

export const RUNNINGSTYLES = {
  en: 'There are 5 differents running styles :\n\n[[Bounce]] : This style is characterized by an intermediate step frequency, a short stance phase, and a long leg oscillation phase. The running motion allows for a more efficient redistribution of stored elastic energy, converting it into high propulsion forces.\n\n[[Hop]] : Defined by a high step frequency and a short stance phase, this style emphasizes limited horizontal propulsion and excessive vertical propulsion, leading to a distinct hopping motion.\n\n[[Push]] : This running style features a relatively low step frequency with a moderate stance phase. The primary focus is on horizontal propulsion rather than vertical, resulting in a flight time slightly shorter than the maximum possible flight time.\n\n[[Stick]] : Characterized by an intermediate step frequency, this style boasts a long stance phase and a short leg oscillation phase. Beneficial at lower speeds, it is ideal for conditions requiring reduced vertical impacts.\n\n[[Sit]] : This style showcases an intermediate step frequency and a balanced ratio between the stance and oscillation phases. A distinctive feature is the likely high knee flexion at the point of ground contact.\n\nSource: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). The biomechanics of running and running styles: a synthesis. Sports biomechanics, 1-39.\n\n',
  fr: "Il existe 5 styles de course différents :\n\n[[Bounce]] : Style de course caractérisé par une fréquence de pas intermédiaire, une phase d'appuie courte et une longue phase d'oscillation de la jambe. Ce style de course permet une meilleur redistribution de l'énergie élastique emmagasiner pendant la phase d'appuie sous forme de forces de propulsions élevées.\n\n[[Hop]] : Style de course caractérisé par une fréquence de pas élevée et une phase d'appuie courte. Ce style de course sugère une propulsion horizontale limitée pendant la phase d'appuie et une propulsion verticale trop élevée.\n\n[[Push]] : Style de course caractérisé par une fréquence de pas relativement faible et phase d'appuie moyenne. Ce style de course favorise une force de propulsion orienté plutôt vers l'horizontal que la vertical, le temps de vol étant légèrement plus court que le temps de vol maximal possible.\n\n[[Stick]] : Style de course caractérisé par une fréquence de pas intermédiaire, une phase d'appuie longue et une phase d'oscillation courte. Ce style de course peut-être bénéfique à vitesses plus faibles ou dans des conditions de course requierant de plus faibles impacts verticaux.\n\n[[Sit]] : Style de course caractérisé par une fréquence de pas et un rapport entre pahse d'appuie phase d'oscillation intermédaires. Ce style de course est probablement caractérisé par une flexion du genou élevée au moment du contact au sol.\n\nSource: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). The biomechanics of running and running styles: a synthesis. Sports biomechanics, 1-39.\n\n",
  zh: '有 5 种不同的跑步方式：\n\n弹跳 ：一种跑步方式，其特点是步频适中、压腿阶段短、摆腿阶段长。这种跑步方式可以更好地重新分配以高推进力形式储存在脚部的弹性能量。\n\n跳 ：跑步方式的特点是步频高、压腿阶段短。这种跑法表明在压腿阶段水平推进力有限，而垂直推进力过大。\n\n猎豹 ：运行方式的特点是步频相对较低，压力相位适中。这种比赛方式更倾向于使用水平而非垂直的推进力。飞行时间比最大可能飞行时间略短。\n\n背负 ：跑步方式的特点是步频适中、压腿阶段长、摆动阶段短。在速度较低或需要较低垂直冲击力的跑步条件下，这种跑步方式会很有益处。\n\n大猩猩 ：以步频和地面接触时间与中间飞行时间之比为特征的跑步方式。这种跑步方式的特点可能是膝盖在接触地面时高度弯曲。\n\n',
};
