import { store } from "store";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

import { auth, firestore } from "utils/firebaseInit";
import { checkUserPermission } from "utils/utils";

const USAGE_COLLECTION = "usages";

export async function getUsage() {
  const user = auth?.currentUser;
  if (user) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const report = (await docs).data();
        return report;
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function removeCredit() {
  const usageStore = store?.getState()?.usage;
  const user = auth?.currentUser;
  if (
    usageStore?.yearlyPlan ||
    (usageStore?.monthlyPlan && checkUserPermission[("coach", "professional")])
  )
    return;
  if (user) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const report = (await docs).data();
        if (report?.monthlyCreditNumber > 0) {
          setDoc(
            ref,
            { monthlyCreditNumber: report.monthlyCreditNumber - 1 },
            { merge: true }
          );
          report.monthlyCreditNumber -= 1;
        } else if (report?.trial > 0) {
          setDoc(ref, { trial: report.trial - 1 }, { merge: true });
          report.trial -= 1;
        } else if (report?.creditNumber > 0) {
          setDoc(
            ref,
            { creditNumber: report.creditNumber - 1 },
            { merge: true }
          );
          report.creditNumber -= 1;
        }
        return report;
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function addCredit(number) {
  const user = auth?.currentUser;
  if (user) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, USAGE_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);

      if ((await docs).exists()) {
        const report = docs.data();
        if (report.creditNumber >= 0 && number >= 0) {
          setDoc(
            ref,
            { creditNumber: report.creditNumber + number },
            { merge: true }
          );
          return report.creditNumber + number;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}
