import React from "react";

import { translate } from "services/remoteConfig";

import TextComponent from "components/atoms/TextComponent";

import { BODY_1, RED } from "constant/constants";

import { ReactComponent as Warning } from "assets/svg/warningStroke.svg";
import ButtonComponent from "components/atoms/ButtonComponent";
import { deleteAnalysis } from "services/analysisApi";
import {
  deleteAnalysisFromProjects,
  deleteAnalysisFromUserList,
} from "services/projectApi";
import { isOwnerOfItem } from "utils/utils";

function ErrorCard({ analysis }) {

  const handleDelete = async () => {
    await deleteAnalysisFromProjects(analysis?.data?.uploadVideoId);
    if (isOwnerOfItem(analysis?.data?.owner)) {
      if (analysis?.data?.tag || Object.keys(analysis?.data?.tag)?.length > 0) {
        await deleteAnalysisFromUserList(
          analysis?.data?.tag?.id,
          analysis?.data?.uploadVideoId
        );
      }
      await deleteAnalysis(analysis?.data?.uploadVideoId);
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.contentContainer}>
        <Warning
          height={100}
          width={100}
          fill={"#FF4117"}
          stroke="#FF4117"
          strokeWidth="3"
        />
        <TextComponent
          text={translate("ErrorAnalyse")}
          variant={BODY_1}
          sx={styles.textTitle}
        />
        <TextComponent
          text={translate("CreditRefund")}
          variant={BODY_1}
          sx={styles.secondaryText}
        />
        <ButtonComponent
          title={translate("Delete")}
          type={RED}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
}
const styles = {
  container: {
    height: "35vh",
    width: "100%",
    maxWidth: "34rem",
    borderRadius: "30px",
    margin: 0,
    border: "5px solid #181818",
    overflow: "hidden",
    backgroundColor: "#181818",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "rgba(255, 65, 23, 0.2)",
    height: "100%",
    borderRadius: 30,
  },
  secondaryText: {
    marginBottom: 1,
    color: "#FF4117",
    textAlign: "center",
  },
  textTitle: {
    marginBottom: 3,
    color: "#FF4117",
    textAlign: "center",
  },
};
export default ErrorCard;
