import {
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {auth} from 'utils/firebaseInit';
import {translate} from './remoteConfig';
import {doc, getFirestore, setDoc} from 'firebase/firestore';
import {GoogleAuthProvider} from 'firebase/auth';

const USER_COLLECTION = 'users';

export async function logInWithEmailAndPassword(
  email,
  password,
  navigate,
  failedCallback,
) {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    ?.then(() => {
      navigate('/');
    })
    ?.catch(error => {
      const errorCode = error.code;
      if (errorCode === 'auth/wrong-password') {
        return failedCallback('', 'AuthErrorWrongPassword');
      } else if (errorCode === 'auth/invalid-email') {
        return failedCallback('AuthErrorInvalidEmail', '');
      } else if (errorCode === 'auth/user-not-found') {
        return failedCallback('', 'AuthErrorUserNotFound');
      } else {
        return failedCallback('errorMessage');
      }
    });
}

export function appleSSOLogin(callBack) {
  const provider = new OAuthProvider('apple.com');

  provider.addScope('email');
  provider.addScope('name');

  signInWithPopup(auth, provider)
    .then(result => {
      const user = result.user;
      callBack(user);
    })
    .catch(error => {
      console.error('Error during Apple Sign-In', error);
    });
}

export function googleSsoLogin(callBack) {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  signInWithPopup(auth, provider)
    .then(result => {
      const user = result.user;
      callBack(user);
      return;
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential);
    });
}

export function logOut() {
  signOut(auth);
}

export async function createUser(email, password) {
  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, email, password).catch(error => {
    if (error.code === 'auth/email-already-in-use') {
      return translate('AuthErrorEmailAlreadyInUse');
    }
    if (error.code === 'auth/invalid-email') {
      return translate('AuthErrorInvalidEmail');
    }
    if (error.code === 'auth/wrong-password') {
      return translate('AuthErrorWrongPassword');
    }
    if (error.code === 'auth/weak-password') {
      return translate('AuthErrorWeakPassword');
    } else {
      //setError(error.code);
      console.log(error);
    }
  });
}

export async function createUserInDB(data) {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();
  const docRef = doc(db, USER_COLLECTION, user.uid);
  await setDoc(docRef, data);
}

export function sendForgotPasswordEmail(
  email,
  successCallback,
  failedCallback,
) {
  const auth = getAuth();

  sendPasswordResetEmail(auth, email)
    .then(() => {
      successCallback();
    })
    .catch(error => {
      console.log(error);
      failedCallback(error);
    });
}

export async function updateUserInDB(data) {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();
  const docRef = doc(db, USER_COLLECTION, user.uid);
  await setDoc(docRef, data, {merge: true});
}
