
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import firebaseConfig from "config/firebaseConfig.json"
import { getFunctions } from 'firebase/functions';

export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase)
export const auth = getAuth(firebase)
export const storage = getStorage(firebase);
export const remoteConfig = getRemoteConfig(firebase);
 export const functions = getFunctions(firebase, 'europe-west3');