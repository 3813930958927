import { USER_LOCALE } from 'services/remoteConfig';
import PrivacyPolicyEn from './privacypolicy.en';
import PrivacyPolicyFr from './privacypolicy.fr';
import { ENGLISH, FRENCH } from 'constant/constants';

let ppTexts;
if (USER_LOCALE === ENGLISH.code) {
  ppTexts = PrivacyPolicyEn;
} else if (USER_LOCALE === FRENCH.code) {
  ppTexts = PrivacyPolicyFr;
} else {
  ppTexts = PrivacyPolicyEn;
}

export default {
  ppTexts
};
