import styled from '@emotion/styled';
import {Button} from '@mui/material';
import TextComponent from 'components/atoms/TextComponent';
import {CONTAINED} from 'constant/constants';
import {useProfileChanges} from 'hooks/useProfileChanges';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  appleSSOLogin,
  createUserInDB,
  googleSsoLogin,
  logOut,
} from 'services/authApi';
import {mpTrack} from 'services/mixpanel';
import {translate} from 'services/remoteConfig';
import {doesUserExist} from 'services/userApi';
import {updateProfileStore} from 'slices/userSlice';
import {colors} from 'themes';
import {auth} from 'utils/firebaseInit';

function SsoButtons({register = false}) {
  useProfileChanges();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function hangleAppleSSOLogin() {
    mpTrack('Web App Apple Sign In Clicked');
    appleSSOLogin(user => {
      doesUserExist(user?.uid).then(data => {
        if (data) {
          navigate('/');
        } else {
          createUserInDB({
            emailAddress: auth.currentUser.email,
            fullName: auth.currentUser.displayName,
            onBoarded: false,
            registered: false,
            registerDate: new Date().toISOString(),
            platform: 'Web app user',
          }).then(() => {
            dispatch(
              updateProfileStore({
                emailAddress: auth.currentUser.email,
                fullName: auth.currentUser.displayName,
                userID: user?.uid,
              }),
            );
            mpTrack('Register Web Button Clicked success');
            mpTrack('Web App Register With Apple');
            navigate('/');
          });
        }
      });
    });
  }

  function handleGoogleSSOLogin() {
    mpTrack('Web App Google Sign In Clicked');
    googleSsoLogin(user => {
      doesUserExist(user?.uid).then(data => {
        if (data) {
          navigate('/');
        } else {
          createUserInDB({
            emailAddress: auth.currentUser.email,
            fullName: auth.currentUser.displayName,
            onBoarded: false,
            registered: false,
            registerDate: new Date().toISOString(),
            platform: 'Web app user',
          }).then(() => {
            dispatch(
              updateProfileStore({
                emailAddress: auth.currentUser.email,
                fullName: auth.currentUser.displayName,
                userID: user?.uid,
              }),
            );
            mpTrack('Register Web Button Clicked success');
            mpTrack('Web App Register With Google');
            navigate('/');
          });
        }
      });
    });
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}>
      <SsoButton
        variant={CONTAINED}
        sx={styles.buttonSSO}
        onClick={handleGoogleSSOLogin}>
        <img
          src={require('assets/images/google.png')}
          alt="google"
          style={{height: '100%', objectFit: 'cover'}}
        />
        {translate(register ? 'GoogleSignUpButton' : 'GoogleSignInButton')}
      </SsoButton>

      <SsoButton
        variant={CONTAINED}
        sx={styles.buttonSSO}
        onClick={hangleAppleSSOLogin}>
        <img
          src={require('assets/images/apple.png')}
          alt="google"
          style={{height: '100%', objectFit: 'cover'}}
        />
        {translate(register ? 'AppleSignUpButton' : 'AppleSignInButton')}
      </SsoButton>
    </div>
  );
}

const SsoButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.WHITE,
  },
});
const styles = {
  buttonSSO: {
    marginTop: '5%',
    width: '60%',
    height: '3rem',
    borderRadius: '20rem',
    fontSize: '1rem',
    fontWeight: '600',
    color: 'black',
    textTransform: 'none',
    backgroundColor: '#E9E9E9',
  },
};
export default SsoButtons;
