import { USER_LOCALE } from 'services/remoteConfig';
import tosEn from './tos.en';
import tosFr from './tos.fr';
import { ENGLISH, FRENCH } from 'constant/constants';

let ppTexts;
if (USER_LOCALE === ENGLISH.code) {
  ppTexts = tosEn;
} else if (USER_LOCALE === FRENCH.code) {
  ppTexts = tosFr;
} else {
  ppTexts = tosEn;
}

export default {
  ppTexts
};
