import React from 'react';

import { Document, Page, Text, View, StyleSheet, pdf, Image, Font } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';

import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import ButtonComponent from 'components/atoms/ButtonComponent';

import { USER_LOCALE, translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { selectBrandLogo } from "slices/userSlice";

import { getAverageScore, getKeyName, getScoreImage, toGoodKey } from 'utils/utils';
import { mToftIn } from 'utils/converter';

import { metricsConfig } from 'screen/analysis/AnalysisViewDetailsScreen/config/metricsConfig';

import { colors, images } from 'themes';

import font from 'assets/fonts/NotoSansSC/NotoSansSC-Regular.otf';
import { BODY_PARTS, DISTANCE_METRIC_FT } from 'constant/constants';


const keyMappings = {
    Head: 'RightEar',
    Trunk: 'RightHip',
    Arm: 'RightElbow',
    ForwardCycle: 'RightKnee',
    BackCycle: 'LeftKnee',
    Foot: 'RightAnkle',
};

const MyDocument = ({ data, jsonData, brandLogo, averageScore, analysisDB, itemsToDisplay, foot, heightUnity, jointAnglesData }) => {
    if (USER_LOCALE === 'zh')
        Font.register({
            family: "NotoSansSC",
            format: "truetype",
            src: font
        });


    const averageScoreText = averageScore >= 66 ? 'score_good' : averageScore >= 33 ? 'score_okay' : 'score_bad';
    const footAsset = jsonData?.mean_foot_landing?.foot_strike_pattern === 'forefoot' ? images.FORE_FOOT : jsonData?.mean_foot_landing?.foot_strike_pattern === 'midfoot' ? images.MID_FOOT : images.REAR_FOOT;
    let sectionIndex = 1;
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const analysisDate = analysisDB?.date
        ? new Date(analysisDB?.date)?.toLocaleDateString(USER_LOCALE, options)
        : "";

    const duty_factor = metricsConfig('duty_factor', jsonData?.metrics?.duty_factor);


    return (
        <Document>
            <Page style={[styles.body, USER_LOCALE === 'zh' ? { fontFamily: 'NotoSansSC' } : null]}>
                <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                    <Image
                        source={brandLogo || images.OCHY_LOGO}
                        style={{ height: 50 }}
                    />
                    <Text style={styles.title}>{analysisDB?.data?.tag?.name}</Text>
                    <Text style={styles.title}>{translate('AnalysisOfDate')} {analysisDate}</Text>
                </View>


                <View style={styles.totalScoreContainer}>
                    <Image
                        source={jsonData?.thumbnail}
                        style={{ height: 150, width: '60%', borderRadius: 15 }}
                    />
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                        <Image style={{ width: 50, height: 50 }} source={getScoreImage(averageScoreText, 'totalScore')} />

                        <View style={{ marginLeft: 15, marginRight: 15, alignItems: 'center' }}>
                            <Text style={{ fontSize: 14 }}>{translate('TotalScore')}</Text>
                            <Text style={{
                                color: score_colors[averageScoreText]?.stroke,
                                backgroundColor: score_colors[averageScoreText].scoreBackground,
                                padding: 5,
                                borderRadius: 20,
                                textAlign: 'center',
                                alignSelf: 'center',
                                fontSize: 14,
                            }}>
                                {averageScoreText === 'score_bad' ? translate('Bad') : averageScoreText === 'score_okay' ? translate('Okay') : translate('Good')}
                            </Text>
                        </View>
                        <Text style={{ color: score_colors[averageScoreText]?.stroke, fontSize: 20 }}>{averageScore}%</Text>
                    </View>
                </View>


                {(data?.length > 0 || foot) &&
                    <>
                        <Text style={styles.subtitle}>{sectionIndex++} {translate('Analysis')}</Text>


                        {data?.map((item, index) => (
                            <View key={index} style={styles.section} wrap={false}>
                                <View style={{ justifyContent: 'space-between', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                    <Text style={{ fontSize: 16, marginBottom: 10, width: '40%' }}>
                                        {translate(Object.keys(keyMappings)?.find(key => keyMappings[key] === item?.type))} : {item?.text1}
                                    </Text>
                                    <View style={styles.card}>
                                        <Text style={styles.cardTitle}>{translate('Recap')}</Text>
                                        {item?.recap?.map((recap, i) => (
                                            <View key={i} style={styles.recapContainer}>
                                                <View style={styles.bulletPoint} />
                                                <Text style={styles.bulletText}>{recap}</Text>
                                            </View>
                                        ))}
                                    </View>
                                </View>
                                <View style={{ marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {index % 2 === 0 ? (
                                        <>
                                            <View style={{ width: '60%' }}>
                                                {Object.entries(item?.segmentedExplanation || {}).map(([key, value], i) => (
                                                    <View key={i} style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
                                                        <Text style={{ fontSize: 12, color: colors.PRIMARY }}>
                                                            <Text style={{ color: colors.SECONDARY, fontSize: 12 }}>
                                                                {key === 'coach_tips' ? translate('CoachTips') : key === 'correction' ? translate('Correction') : translate('Consequences')} :{' '}
                                                            </Text>
                                                            {value}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                            <View style={{ width: '40%', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ position: 'relative', width: '100%', height: 150 }}>
                                                    <Image
                                                        source={images[toGoodKey(item?.analysisKey, item?.angle)]}
                                                        style={{ position: 'absolute', width: '100%', height: '100%', borderRadius: 15, objectFit: 'contain' }}
                                                    />
                                                    <Image
                                                        source={images[getKeyName(item?.analysisKey, item?.angle)]}
                                                        style={{ position: 'absolute', width: '100%', height: '100%', borderRadius: 15, objectFit: 'contain' }}
                                                    />
                                                </View>
                                                <Text style={{ marginTop: 10, fontSize: 12 }}>
                                                    {translate('Angle')} : {item?.angle?.toFixed(0)}°
                                                </Text>
                                                <Text style={{ fontSize: 12 }}>
                                                    {translate('GoodRange')} : {item?.rangeGood[0]}°  {item?.rangeGood[1]}°
                                                </Text>
                                            </View>
                                        </>
                                    ) : (
                                        <>
                                            <View style={{ width: '40%', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ position: 'relative', width: '100%', height: 150 }}>
                                                    <Image
                                                        source={images[toGoodKey(item?.analysisKey, item?.angle)]}
                                                        style={{ position: 'absolute', width: '100%', height: '100%', borderRadius: 15, objectFit: 'contain' }}
                                                    />
                                                    <Image
                                                        source={images[getKeyName(item?.analysisKey, item?.angle)]}
                                                        style={{ position: 'absolute', width: '100%', height: '100%', borderRadius: 15, objectFit: 'contain' }}
                                                    />
                                                </View>
                                                <Text style={{ marginTop: 10, fontSize: 12 }}>
                                                    {translate('Angle')} : {item?.angle?.toFixed(0)}°
                                                </Text>
                                                <Text style={{ fontSize: 12 }}>
                                                    {translate('GoodRange')} : {item?.rangeGood[0]}°  {item?.rangeGood[1]}°
                                                </Text>
                                            </View>
                                            <View style={{ width: '60%' }}>
                                                {Object.entries(item?.segmentedExplanation || {}).map(([key, value], i) => (
                                                    <View key={i} style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
                                                        <Text style={{ fontSize: 12, color: colors.PRIMARY }}>
                                                            <Text style={{ color: colors.SECONDARY, fontSize: 12 }}>
                                                                {key === 'coach_tips' ? translate('CoachTips') : key === 'correction' ? translate('Correction') : translate('Consequences')} :{' '}
                                                            </Text>
                                                            {value}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        </>
                                    )}
                                </View>
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'gray', borderStyle: 'dashed', marginTop: 10 }} />
                            </View>
                        ))}


                        {foot && <View style={styles.section}>
                            <View style={{ marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Image
                                    src={footAsset}
                                    style={{ height: 75, width: '30%', backgroundColor: colors.APP_BACKGROUND, borderRadius: 15, objectFit: 'contain' }}
                                />
                                <View style={{ width: '60%' }}>
                                    <Text style={{ fontSize: 16, marginBottom: 5, color: colors.SECONDARY }}>{foot?.text1}</Text>
                                    <Text style={[{ color: colors.PRIMARY, fontSize: 12 }]}>{foot?.text2}</Text>

                                </View>

                            </View>

                        </View>}
                    </>}
                {itemsToDisplay[1]?.Metrics?.length > 0 &&
                    <>
                        <Text style={styles.subtitle}>{sectionIndex++} {translate('Metrics')}</Text>

                        {itemsToDisplay[1]?.Metrics?.includes('time_of_flight') &&
                            <View style={styles.section} wrap={false}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '65%' }}>
                                        <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{jsonData?.metrics?.time_of_flight} {translate('Sec')}</Text>
                                        <Text style={styles.text}>{translate("TimeBetweenStepsDescription")?.replace(/\n\n/g, ' ')}</Text>
                                    </View>
                                    <Image source={images.METRICS_TIME_STEPS} style={{ height: 75, width: '30%', borderRadius: 15, backgroundColor: colors.APP_BACKGROUND, objectFit: 'contain' }} />

                                </View>
                            </View>}

                        {itemsToDisplay[1]?.Metrics?.includes('ground_contact_time') &&
                            <View style={styles.section} wrap={false}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Image source={images.METRICS_GROUND_CONTACT_TIME} style={{ height: 75, width: '30%', borderRadius: 15, backgroundColor: colors.APP_BACKGROUND, objectFit: 'contain' }} />

                                    <View style={{ width: '65%' }}>
                                        <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{jsonData?.metrics?.ground_contact_time} {translate('Sec')}</Text>
                                        <Text style={styles.text}>{translate("GroundContactTimeDescription")}</Text>
                                    </View>
                                </View>
                            </View>}

                        {itemsToDisplay[1]?.Metrics?.includes('steps_per_min') &&
                            <View style={styles.section} wrap={false}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '65%' }}>
                                        <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{jsonData?.metrics?.steps_per_min} {translate("PerMin")}</Text>
                                        <Text style={styles.text}>{translate("StepFrequencyDescription")?.replace(/\n\n/g, ' ')}</Text>
                                    </View>
                                    <Image source={images.METRICS_NUMBER_STEPS} style={{ height: 75, width: '30%', borderRadius: 15, backgroundColor: colors.APP_BACKGROUND, objectFit: 'contain' }} />
                                </View>
                            </View>}

                        {itemsToDisplay[1]?.Metrics?.includes('duty_factor') &&
                            <View style={styles.section} wrap={false}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '30%' }}>
                                        <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>Duty factor</Text>
                                        <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{duty_factor?.value} {duty_factor?.unity}</Text>
                                    </View>
                                    <Text style={{ width: '65%', fontSize: 12, }}>{translate('DutyFactorDesc')}</Text>
                                </View>
                            </View>}

                        {itemsToDisplay[1]?.Metrics?.includes('stride_length') &&
                            <View style={styles.section} wrap={false}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '65%' }}>
                                        {heightUnity === DISTANCE_METRIC_FT ? <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{mToftIn(jsonData?.metrics?.step_length * 2)?.ft} ft {mToftIn(jsonData?.metrics?.step_length * 2)?.inch} in</Text> :
                                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{jsonData?.metrics?.step_length * 2} {translate('Meters')}</Text>
                                        }

                                        <Text style={styles.text}>{translate("StrideLengthDesc")?.replace(/\n\n/g, ' ')}</Text>
                                    </View>
                                    <Image source={images.METRICS_STRIDE_LENGTH} style={{ height: 75, width: '30%', borderRadius: 15, backgroundColor: colors.APP_BACKGROUND, objectFit: 'contain', padding: 5 }} />

                                </View>
                            </View>}

                        {itemsToDisplay[1]?.Metrics?.includes('step_length') &&
                            <View style={styles.section} wrap={false}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Image source={images.METRICS_STEP_LENGTH} style={{ height: 75, width: '30%', borderRadius: 15, backgroundColor: colors.APP_BACKGROUND, objectFit: 'contain', padding: 5 }} />

                                    <View style={{ width: '65%' }}>
                                        {heightUnity === DISTANCE_METRIC_FT ? <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{mToftIn(jsonData?.metrics?.step_length)?.ft} ft {mToftIn(jsonData?.metrics?.step_length)?.inch} in</Text> :
                                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>{jsonData?.metrics?.step_length} {translate('Meters')}</Text>
                                        }
                                        <Text style={styles.text}>{translate("StepLengthDesc")}</Text>
                                    </View>
                                </View>
                            </View>}
                    </>}

                {itemsToDisplay[2]?.JointAngles?.includes('joint_angles') &&
                    <View wrap={false}>
                        <Text style={styles.subtitle}>{sectionIndex++} {translate('JointAngles')}</Text>
                        <View style={styles.section}>
                            <View style={{ alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>

                                <Image source={{ uri: jointAnglesData?.chart }} style={{ objectFit: 'cover', width: '60%' }} />
                                <View style={{ width: '40%' }}>
                                    <View style={{ flexDirection: 'row', marginBottom: 10, }}>
                                        <Text style={{ fontSize: 12, width: '50%', textAlign: 'center' }}>{translate('JointAngles')}</Text>
                                        <Text style={{ fontSize: 12, width: '25%', textAlign: 'center' }}>{translate('Left')}</Text>
                                        <Text style={{ fontSize: 12, width: '25%', textAlign: 'center' }}>{translate('Right')}</Text>
                                    </View>
                                    {jointAnglesData?.data?.map((item, index) => {
                                        if (index % 2 === 1) return null;

                                        // S'assure qu'au moins un des deux éléments est sélectionné
                                        if (!item?.selected && !jointAnglesData?.data[index + 1]?.selected) return null;

                                        return (
                                            <View key={index} style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginBottom: 10,
                                            }}>
                                                <View style={{
                                                    padding: 10,
                                                    width: '50%',
                                                    textAlign: 'center',
                                                }}>
                                                    <Text style={{ fontSize: 12 }}>
                                                        {translate(BODY_PARTS[Math.floor(index / 2)])}
                                                    </Text>
                                                </View>
                                                {item?.selected ? <View style={{
                                                    width: '20%',
                                                    height: 22,
                                                    margin: '0 5px',
                                                    lineHeight: '22px',
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: 'black',
                                                    backgroundColor: item?.color
                                                }} />

                                                    : <Text style={{ textAlign: 'center', fontSize: 12, color: 'red', width: '20%', fontWeight: 'bold', margin: '0 5px', }}>
                                                        {'X'}
                                                    </Text>
                                                }
                                                {jointAnglesData?.data[index + 1]?.selected ? <View style={{
                                                    width: '20%',
                                                    height: 22,
                                                    margin: '0 5px',
                                                    lineHeight: '22px',
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: 'black',
                                                    backgroundColor: jointAnglesData?.data[index + 1]?.color
                                                }} />

                                                    : <Text style={{ textAlign: 'center', fontSize: 12, color: 'red', width: '20%', fontWeight: 'bold', margin: '0 5px', }}>
                                                        {'X'}
                                                    </Text>
                                                }

                                            </View>
                                        );
                                    })}
                                </View>
                            </View>
                        </View>
                    </View>
                }

                {itemsToDisplay[3]?.RunningStyle?.includes('running_styles') &&
                    <View wrap={false}>
                        <Text style={styles.subtitle}>{sectionIndex++} {translate('RunningStyleTitle')}{jsonData?.running_styles[0]?.title}</Text>
                        <View style={styles.section}>
                            <View style={{ alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', }}>

                                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Image source={jsonData?.running_styles[0]?.asset} style={{ height: 100, borderRadius: 15, backgroundColor: colors.APP_BACKGROUND, padding: 10 }} />
                                    <Text style={{ fontSize: 16, marginTop: 10 }}>{jsonData?.running_styles[0]?.animal}</Text>
                                </View>
                                <View style={{ alignItems: 'center', width: '75%' }}>
                                    <Text style={styles.text}>
                                        {jsonData?.running_styles[0]?.animalExplanation + '\n' + jsonData?.running_styles[0]?.title + ' : ' + jsonData?.running_styles[0]?.summary}
                                    </Text>

                                </View>
                            </View>
                        </View>
                    </View>
                }


                {itemsToDisplay[4]?.Comments &&
                    <View wrap={false}>
                        <Text style={styles.subtitle}>{sectionIndex++} {translate('Notes')}</Text>
                        <View style={styles.section}>
                            <Text style={styles.text}>{itemsToDisplay[3]?.Comments}</Text>
                        </View>
                    </View>
                }

            </Page>
        </Document >
    )
}

const styles = StyleSheet.create({
    body: {
        paddingHorizontal: 40,
        paddingVertical: 20,
    },
    title: {
        fontSize: 20,
    },
    subtitle: {
        color: colors.PRIMARY,
        fontSize: 20,
        borderBottomWidth: 1,
        borderBottomColor: colors.PRIMARY,
        borderLeftWidth: 1,
        borderLeftColor: colors.PRIMARY,
        marginBottom: 10,
        padding: 5,
    },
    text: {
        fontSize: 12,
        marginBottom: 10,
    },
    section: {
        padding: 10,
        marginBottom: 10,
    },
    card: {
        backgroundColor: '#f8f9fa', // Couleur de fond de la carte
        padding: 10,
        borderRadius: 10,
        border: '1px solid #ddd',
        marginTop: -10,
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    bulletPoint: {
        width: 5,
        height: 5,
        borderRadius: 2.5,
        backgroundColor: 'black',
        marginRight: 5,
    },
    bulletText: {
        fontSize: 12,
    },
    recapContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    totalScoreContainer: { marginBottom: 20, alignItems: 'center', justifyContent: 'center' },

});

const GeneratePDF = ({ data, jsonData, itemsToDisplay, setSucces, step, setStep, exportJointAnglesToPDF }) => {
    const brandLogo = useSelector(selectBrandLogo);
    const analysisData = useSelector((state) => state.analysis.listAnalysisData);
    const analysisDB = analysisData?.find((item) => item?.data?.uploadVideoId === jsonData?.video_id)
    const averageScore = getAverageScore(Object.entries(data));
    const heightUnity = useSelector((state) => state.unity.height);

    function onGeneratePDF() {
        if ((step === 1 && itemsToDisplay[2]?.JointAngles === 'joint_angles') || (step === 0 && (itemsToDisplay[2]?.JointAngles !== 'joint_angles'))) {
            generatePDF()
                .then(() => {
                    setSucces(true);
                    mpTrack('WebApp Generate PDF button clicked');
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);
                });
        }
        else {
            setStep(1);
        }

    }

    const generatePDF = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const jointAnglesData = itemsToDisplay[2]?.JointAngles ? await exportJointAnglesToPDF() : null;
                const doc = <MyDocument
                    data={itemsToDisplay[0]?.Segments?.length > 0 && data?.slice(1)?.filter((item) => itemsToDisplay[0]?.Segments?.includes(item?.type))}
                    itemsToDisplay={itemsToDisplay}
                    jsonData={jsonData}
                    brandLogo={brandLogo}
                    averageScore={averageScore}
                    analysisDB={analysisDB}
                    foot={itemsToDisplay[0]?.Segments?.includes('RightAnkle') && data[0]}
                    heightUnity={heightUnity}
                    jointAnglesData={jointAnglesData}
                />;
                const asPdf = pdf([]);
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${analysisDB?.data?.tag?.name}_${new Date(analysisDB?.date).toLocaleDateString(USER_LOCALE, {
                    month: "numeric",
                    day: "numeric",
                })}.pdf`;
                a.click();
                URL.revokeObjectURL(url);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div style={{ marginTop: "2vh" }}>
            <ButtonComponent type="orange" onClick={onGeneratePDF} title={translate('GeneratePDF')} />
        </div>
    );
};



export default GeneratePDF;
