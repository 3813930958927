import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import tos from "assets/pp&tos/tos";

import { BODY_1, H3 } from "constant/constants";

function TermsNConditions() {
  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <TextComponent text={translate("TermsTitle")} variant={H3} />
        <TextComponent
          sx={styles.text}
          variant={BODY_1}
          text={tos?.ppTexts?.text}
        />
      </div>
    </div>
  );
}
const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "85vh",
    overflowY: "auto",
  },
  textContainer: {
    width: "80%",
    marginTop: "5%",
  },
  text: {
    marginTop: 4,
  },
};
export default TermsNConditions;
